<template>
    <div class="c-image-upload__wrap">
        <div class="c-image-upload__img"
             v-for="(img, indexImg) in data"
             :key="'img_'+img.id">
            <img :src="path + img.url_thumb" />
            <div class="c-image-upload__controls">
    
                <b-tabs :class="languages.length == 1 ? 'c-image-upload__title-tab--single' : '' " class="c-image-upload__title-tab">
                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                        <b-form-textarea
                            v-model="img['name_'+lang.name]"
                            :key="img.id"
                            class="content-editor__lang-input"
                            :class="languages.length == 1 ? 'content-editor__lang-input--single' : '' "
                            max-rows="3"
                            placeholder="Popis obrázku"
                            row="1"
                            @blur="updateTitle()"
                        >
                        </b-form-textarea>
                    </b-tab>
                </b-tabs>
                
                <b-button v-if="indexImg !== 0" class="ml-1" size="sm" variant="info"
                          @click="sortImg('up', img.sort)">
                    <b-icon icon="arrow-up-left" variant="white"></b-icon>
                </b-button>
                <b-button v-if="data.length-1 !== indexImg" class="ml-1" size="sm" variant="info"
                          @click="sortImg('down', img.sort)">
                    <b-icon icon="arrow-down-right" variant="white" ></b-icon>
                </b-button>
                <b-button class="ml-2 mr-1" size="sm" variant="danger"
                          @click="deleteFile(img.id)">
                    <b-icon icon="trash" variant="white"></b-icon>
                </b-button>
            </div>
        </div>

        <div class="c-image-upload__img c-image-upload__img--new">
            <b-icon class="c-image-upload__add"
                    icon="plus-circle-fill"
                    variant="info"></b-icon>

            <div class="c-file-upload">

                <label class="c-file-upload__label" :for="'file_'+uniqId" >
                    <input type="file"
                           :id="'file_'+uniqId"
                           :ref="'file_'+uniqId"
                           @change="changeFileName()"
                           multiple="multiple"
                           accept="image/*">
                </label>

                <b-progress v-if="uploading" class="c-file-upload__progress" :value="uploadPercentage" :max="'100'" animated></b-progress>

            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: "images-controller",
        props: {
            nameForeignTable: String,
            idForeignTable: Number,
            imgHeight: {
                type: Number,
                default: 1000
            },

        },
        data(){
            return {
                path: process.env.VUE_APP_SERVER,
                file: [],
                data: [],
                uploadPercentage: 0,
                uploading: false,
                uniqId: null
            }
        },
        methods: {
            changeFileName() {

                this.file = this.$refs['file_'+this.uniqId].files;
                this.uploadFile();
            },

            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/images-controller/list.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'GET',
                        params: {
                            'name_foreign_table': this.nameForeignTable,
                            'id_foreign_table': this.idForeignTable,
                        }
                    });

                    this.data = response.data.records;

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            },

            async uploadFile() {

                this.uploading = true;

                let formData = new FormData();
                for( var i = 0; i < this.$refs['file_'+this.uniqId].files.length; i++ ){
                    let file = this.$refs['file_'+this.uniqId].files[i];
                    formData.append('file[' + i + ']', file);
                }

                formData.append('id_foreign_table', this.idForeignTable);
                formData.append('name_foreign_table', this.nameForeignTable);
                formData.append('img_height', this.imgHeight);

                try {
                    await this.$http.post( process.env.VUE_APP_SERVER + 'api/images-controller/add-images.php',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        }
                    );

                    this.loadData();
                    this.file = [];
                    this.uploading = false;
                    this.uploadPercentage = 0;
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.file_uploaded'));

                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                    this.file = [];
                    this.uploading = false;
                }
            },
            async sortImg(direction, sort) {
                try {

                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/images-controller/sort-images.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: {
                            'sort':sort,
                            'direction': direction,
                            'name_foreign_table': this.nameForeignTable,
                            'id_foreign_table': this.idForeignTable,
                        },
                        method: 'POST'
                    });
                    this.loadData();

                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.error'));
                }
            },
            async updateTitle() {
                try {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/images-controller/update-item.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: this.data,
                        method: 'PUT'
                    });
                    
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            },
            async deleteFile(id) {
                try {
                    if (confirm(this.$t('projects.deleteProject'))) {
                        await this.$http({
                            url: process.env.VUE_APP_SERVER + 'api/images-controller/remove-item.php',
                            headers: {'content-type': 'application/x-www-form-urlencoded'},
                            data: {
                                'id': id
                            },
                            method: 'DELETE'
                        });
                        this.loadData();
                        this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                    }

                } catch (error) {
                    this.loadData();
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },
        },
        computed: {
            languages() {
                return this.$store.getters.langs
            },
        },
        mounted() {
            this.loadData();
            this.uniqId = this._uid
        },
    }
</script>

<style lang="scss" scoped>

</style>
